import {
  PurchasableItemPurchaseItemRequestDto
} from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/purchasable-item';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { globalErrorHandler } from '../../../utils/LogUtils';
import { getLogo } from '../../atoms/PrerollLogo/PrerollLogo';
import {
  GEMS_COST_PREROLL,
  GEMS_TEST_SKU,
  skipPrerollItem,
  skipPrerollVirtualItem
} from '../../constants/GemsConstants';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { GameState } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { Analytics } from '../../services/Analytics/Analytics';
import { LeanplumGemsTrack } from '../../services/Analytics/LeanplumAnalytics';
import { AppInsightService } from '../../services/AppInsight';
import GemsService from '../../services/GemsService';
import { LocalStorageService } from '../../services/LocalStorage';
import { TranslationService } from '../../services/TranslationService';
import { UrlService } from '../../services/UrlService';
import UserService from '../../services/UserService';
import { AppState } from '../../store';
import { setGameState } from '../../store/ducks/games';
import { setTrySpendGems } from '../../store/ducks/gems/gems';
import { setSideMenuActivePage } from '../../store/ducks/layout';
import {
  GemsAnalyticsShopLocations,
  LS_SHOP_LOCATION_PROP,
  setGemsShopLocation
} from '../../store/ducks/leanplum/lpAnalytics';
import { GemsEffects } from '../../store/effects/gems.effects';

type TGetActualProps = {
  gemsAmount: number;
  prerollSkipPrice: number;
  isPrerollSkipPriceLoading: boolean;
  isGemsAmountLoading: boolean;
  gemsShopLocation?: any;
}

export async function skipAdsButtonHandler(
  dispatch: ThunkDispatch<AppState, void, Action>,
  game: IGame,
  getActualProps: TGetActualProps
) {
  const { gemsAmount, prerollSkipPrice, isPrerollSkipPriceLoading, isGemsAmountLoading } = getActualProps;

  await Analytics.trackEvent(Analytics.gems.gemSkipAdButton(prerollSkipPrice, game));

  if (!UserService.isUserLoggedIn()) {
    openShopPanel(dispatch);
  } else if (
    !isGemsAmountLoading &&
    !isPrerollSkipPriceLoading &&
    prerollSkipPrice &&
    gemsAmount >= prerollSkipPrice
  ) {
    const item: [PurchasableItemPurchaseItemRequestDto[], string, number, string] = [
      [skipPrerollItem],
      GEMS_TEST_SKU,
      prerollSkipPrice,
      game.slug
    ];

    // set game state assuming user have enough gems
    dispatch(setGameState(GameState.GAME));
    GemsService.purchaseItem(...item)
      .then(() => {
        dispatch(GemsEffects.UpdateGemsAmount());
        GemsService.consumeUserInventory(skipPrerollVirtualItem)
          .then(async () => {
            const { gemsShopLocation, prerollSkipPrice } = getActualProps;
            const shopLocation = LocalStorageService.getItem(LS_SHOP_LOCATION_PROP) || gemsShopLocation;

            LeanplumGemsTrack.virtualItemSpend({
              virtualItemId: skipPrerollVirtualItem.items[0].sku,
              internalPrice: prerollSkipPrice,
              shopLocation
            }).then(r => r);
            await Analytics.trackEvent(Analytics.gems.gemSpendingSkipAdSuccess(prerollSkipPrice));
          })
          .catch((err) => {
            // allow user to play the game even if purchase was not successful
            globalErrorHandler({
              error: err,
              filename: 'PrerollHelpers.ts',
              info: 'GemsService.consumeUserInventory()'
            });
            AppInsightService.trackAppError(err, { data: 'consumeUserInventory()' });
          });
      })
      .catch((err) => {
        // unsuccessfully purchase request - allow user to play the game even if purchase was not successful
        globalErrorHandler({ error: err, filename: 'PrerollHelpers.ts', info: 'GemsService.purchaseItem()' });
        AppInsightService.trackAppError(err, { data: 'purchaseItem()' });
      });
  } else {
    if (!isPrerollSkipPriceLoading && !isGemsAmountLoading) {
      openShopPanel(dispatch);
    }
  }
}

function openShopPanel(dispatch: Dispatch<Action>) {
  batch(() => {
    // to show notification message in right sidebar
    dispatch(setTrySpendGems('preroll'));
    dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SHOP_TAB));
    dispatch(setGemsShopLocation(GemsAnalyticsShopLocations.PREROLL));
  });
}

export function getTagLine(plusFeatureFlag: boolean): string {

  return `
        <ark-ad-div class = "ctaWrapper">
            <div class = "ctaHeader">
                <div class = "ctaArkTagLine">
                    <div class = "ctaSlogan">
                        ${TranslationService.translateIt('PREROLL_MESSAGE')}
                    </div>
                </div>
            </div>
            <div class = "ctaFooter">
                <div class = "ctaLogo">${getLogo()}</div>
                <div class = "ctaLogoSlogan">${TranslationService.translateIt('PREROLL_TAGLINE')}</div>
            </div>
            <button 
                type = "button" 
                role="link" 
                class = "ctaRightButton">
                <span>${TranslationService.translateIt('PREROLL_SKIP_AD')}</span>
                <div class="ctaRightButtonVisibility">
                    <img src="/illustrations/gems/gem.png" alt="Gem" /><span>${GEMS_COST_PREROLL}</span>
                </div>
                <div class="ctaRightButtonLoadingVisibility"><span>
                    <svg class="spinnerPreroll" role="img" aria-label="loader-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66">
                       <circle class="pathPreroll" stroke="#08B238" fill="none" cx="33" cy="33" r="30"></circle>
                    </svg>
                </span></div>
            </button>          
            <a href=${plusFeatureFlag ? UrlService.createURL('plus') : 'https://advantage.arkadium.com'}
                rel="noopener noreferrer"
                aria-label="Arkadium plus" 
                target="_blank" 
                class = "ctaRemoveAdsText"
            >
                ${plusFeatureFlag
    ? TranslationService.translateIt('PREROLL_REMOVE_ADS_PLUS')
    : TranslationService.translateIt('PREROLL_REMOVE_ADS')
  }
            </a>
    `;
}
