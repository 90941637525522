import React, { useEffect } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import styles from './PrerollPreloader.css';
import { getLogo } from '../../atoms/PrerollLogo/PrerollLogo';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { LS_COOKIE_CONSTS } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { SubscriptionSource } from '../../models/Subscription/SubscriptionData';
import { Analytics } from '../../services/Analytics/Analytics';
import UserService from '../../services/UserService';
import { setSubscriptionSource } from '../../store/ducks/subscription/common';

const logo = getLogo();

type SubscriberOnlyPrerollProps = {
  game: IGame;
};
export const SubscriberOnlyPreroll = React.memo(({ game }: SubscriberOnlyPrerollProps) => {
  const { subscriberOnlyGame, name } = game;
  const dispatch = useDispatch();
  const availableDateFormatted = dayjs(subscriberOnlyGame).format('MMM DD, YYYY');
  const handleClick = () => {
    document.cookie = `${LS_COOKIE_CONSTS.GAME_SLUG_FOR_SUBSCRIPTION_PURCHASE}=${game.alias};path=/;`;
    Analytics.trackEvent(Analytics.games.earlyAccessJoinButton(game));
    dispatch(setSubscriptionSource(SubscriptionSource.EARLY_ACCESS));
    UserService.openSubscriptionPanel();
  };

  useEffect(() => {
    Analytics.trackEvent(Analytics.games.earlyAccessJoinButtonImpression(game));
  }, []);

  return (
    <div className={styles.preloaderWrapper}>
      <div className={classNames(styles.preloaderContent, styles.preloaderContentSubsOnly)}>
        <div className={styles.preloaderContentWrapper}>
          <div className={styles.subsOnlyTitle}>
            {`${name} is exclusive to`}
                            <p>Arkadium Plus Subscribers</p>
          </div>
          <div className={styles.subsOnlySubTitle}>
            Not a subscriber?
          </div>
          <Button className={styles.joinBtn} onClick={handleClick}>
            Learn more
          </Button>

          <div className="ctaFooter">
            <div className="ctaLogo" dangerouslySetInnerHTML={{ __html: logo }} />
            <div className="ctaLogoSlogan">Games you can feel good about™</div>
          </div>
        </div>
      </div>
    </div>
  );
});
