import React from 'react';

import classNames from 'classnames';

import styles from './Loader.css';

export const GameLoader = () => (
    // <LoaderContainer fullscreen = {DeviceUtils.isPhoneOrTabletDevice()}>
    // trans_key = GAME_LOADER
    <LoaderContainer fullscreen={false}>
        <LoaderText>
            <LoadingLetter>L</LoadingLetter>
            <LoadingLetter>O</LoadingLetter>
            <LoadingLetter>A</LoadingLetter>
            <LoadingLetter>D</LoadingLetter>
            <LoadingLetter>I</LoadingLetter>
            <LoadingLetter>N</LoadingLetter>
            <LoadingLetter>G</LoadingLetter>
        </LoaderText>
    </LoaderContainer>
);

const LoaderContainer = ({ fullscreen, ...props }: any) => (
    <div
        className={classNames(styles.loaderContainer, {
            [styles.fullscreen]: !!fullscreen,
        })}
        {...props}
    />
);

const LoaderText = (props: any) => <div className={styles.loaderText} {...props} />;

const LoadingLetter = (props: any) => <span className={styles.loadingLetter} {...props} />;
