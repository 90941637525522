import React, { useEffect } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import styles from './PrerollPreloader.css';
import { getLogo } from '../../atoms/PrerollLogo/PrerollLogo';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { GameState } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { Analytics } from '../../services/Analytics/Analytics';
import { setGameState } from '../../store/ducks/games';

const logo = getLogo();

type NoPrerollProps = {
  game: IGame;
};
export const NoPreroll = React.memo(({ game }: NoPrerollProps) => {
  const { name } = game;
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setGameState(GameState.GAME));
  };

  return (
      <div className={styles.preloaderWrapper}>
        <div className={classNames(styles.preloaderContent, styles.preloaderContentSubsOnly)}>
          <div className={styles.preloaderContentWrapper}>
            <div className={styles.noPrerollOnlyTitle}>
              {`${name}`}
            </div>
            <Button className={styles.noPrerollJoinBtn} onClick={handleClick}>
              Play Now
            </Button>

            <div className="ctaFooter">
              <div className="ctaLogo" dangerouslySetInnerHTML={{ __html: logo }} />
              <div className="ctaLogoSlogan">Games you can feel good about™</div>
            </div>
          </div>
        </div>
      </div>
  );
});
