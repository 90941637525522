import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import styles from './AdBlockScreen.css';
import { MiscUtils } from '../../../utils/MiscUtils';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { ArkadiumPlusLogo } from '../../atoms/Icons/Styleguide/ArkadiumPlusLogo';
import { NavLink } from '../../atoms/Link/Link';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { AdblockEyeIcon } from '../../FigmaStyleguide/Icons/AdblockEyeIcon';
import { AdsIcon } from '../../FigmaStyleguide/Icons/AdsIcon';
import { ArkadiumAdvantageLogo } from '../../FigmaStyleguide/Icons/ArkadiumAdvantageLogo';
import { CustomAvatar } from '../../FigmaStyleguide/Icons/CustomAvatars';
import { DiscountIcon } from '../../FigmaStyleguide/Icons/DiscountIcon';
import { LS_COOKIE_CONSTS } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { SubscriptionSource } from '../../models/Subscription/SubscriptionData';
import { Analytics } from '../../services/Analytics/Analytics';
import UserService from '../../services/UserService';
import { reduxStore } from '../../store';
import { setSubscriptionSource } from '../../store/ducks/subscription/common';

type AdBlockScreenProps = {
  game: IGame;
};

const { store } = reduxStore;
const BACKGROUND_GRADIENT = 'linear-gradient(0deg, rgba(30, 42, 121, 0.8), rgba(30, 42, 121, 0.8))';

export const AdBlockScreen = React.memo(
  ({ game }: AdBlockScreenProps) => {
    const plusFeatureFlag = useSelector(({ arkConfig }) => arkConfig?.plusFeature?.isEnabled);
    const backgroundImage = `${BACKGROUND_GRADIENT}, url(${game.promoTile1000x312})`;

    useEffect(() => {
      if (!MiscUtils.isServer) {
        Analytics.trackEvent(Analytics.games.adblockImpression(game));
      }
    }, []);

    const buttonOnClickHandler = () => {
      document.cookie = `${LS_COOKIE_CONSTS.GAME_SLUG_FOR_SUBSCRIPTION_PURCHASE}=${game.alias};path=/;`;
      store.dispatch(setSubscriptionSource(SubscriptionSource.ADBLOCKER));
      Analytics.trackEvent(Analytics.subscription.removeAdsAdBlockScreen());
      UserService.openSubscriptionPanel();
    };

    return (
      <Container data-element-description="ad block" aria-hidden="true" style={{ backgroundImage }}>
        <div className={styles.adblockerHeader}>
          <div className={styles.adblockerHeaderIconContainer}>
            <AdblockEyeIcon className={styles.adblockerHeaderIcon} />
          </div>
        </div>

        <div className={styles.adblockerSectionTop}>
          <I18nText as="p" keyName="AD_BLOCK_FIRST_LINE" className={styles.adblockerTextTop} />
          <I18nText as="p" keyName="AD_BLOCK_SECOND_LINE" className={styles.adblockerTextMiddle} html />
          <p className={styles.adblockerTextBottom}>
            <I18nText keyName="AD_BLOCK_THIRD_LINE" />{' '}
            <NavLink
              className={styles.helpPageLink}
              to="https://support.arkadium.com/en/support/solutions/articles/44001739800-i-received-a-message-about-my-ad-blocker-what-should-i-do-"
              onClick={() => {
                Analytics.trackEvent(Analytics.games.adblockClick(game));
              }}
            >
              <I18nText keyName="AD_BLOCK_LINK" />
            </NavLink>
          </p>
        </div>

        <div className={styles.adblockerSectionBottom}>
          <div className={styles.adblockerSectionBottomLeft}>
            {plusFeatureFlag ?
              <div className={styles.adblockerPlusIcon}>
                <ArkadiumPlusLogo />
              </div> :
              <ArkadiumAdvantageLogo className={styles.adblockerAdvantageIcon} />}

            <div className={styles.adblockerBottomLeftText}>
              <span className={styles.adblockerTextSelected}>All of the fun.</span> None of the ads
            </div>
            <Button className={styles.removeAdsButton} onClick={buttonOnClickHandler}>
              Remove ads
            </Button>
          </div>

          <div className={styles.adblockerSectionBottomRight}>
            <div className={styles.benefitItem}>
              <div className={styles.benefitIconContainer}>
                <AdsIcon className={styles.benefitIcon} />
              </div>
              No disruptive ads
            </div>
            <div className={styles.benefitItem}>
              <div className={styles.benefitIconContainer}>
                <DiscountIcon className={styles.benefitIcon} />
              </div>
              {plusFeatureFlag ? '30%' : '15%'} discount on purchases
            </div>
            <div className={styles.benefitItem}>
              <div className={styles.benefitIconContainer}>
                <CustomAvatar className={styles.benefitIcon} />
              </div>
              Subscriber-only avatars
            </div>
          </div>
        </div>
      </Container>
    );
  },
  () => true
);

const Container = (props: any) => {
  const containerStyles = classNames(styles.container, props.className);

  return <div {...props} className={containerStyles} />;
};
