import React from 'react';

import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Action, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import serialize from 'serialize-javascript';


import styles from './Preroll.css';
import { getTagLine, skipAdsButtonHandler } from './PrerollHelpers';
import { HttpUtils } from '../../../utils/HttpUtils';
import { globalErrorHandler } from '../../../utils/LogUtils';
import { environment } from '../../config/environment';
import { IGame } from '../../models/Game/Game';
import { SubscriptionSource } from '../../models/Subscription/SubscriptionData';
import { Analytics } from '../../services/Analytics/Analytics';
import { TranslationService } from '../../services/TranslationService';
import { UrlService } from '../../services/UrlService';
import { AppState } from '../../store';
import {
  gemsAmountSelector,
  gemsShopLocationSelector,
  isGemsAmountLoadingSelector,
  isPrerollSkipPriceLoadingSelector,
  prerollSkipPriceSelector
} from '../../store/ducks/gems/gemsSelectors';
import { setSubscriptionSource } from '../../store/ducks/subscription/common';

type StateProps = {
  isPrerollSkipPriceLoading: boolean;
  isGemsAmountLoading: boolean;
  gemsAmount: number;
  prerollSkipPrice: number | null;
  userFavoritesList: string[];
  plusFeatureFlag: boolean;
};

type DispatchProps = {
  setSubscriptionSource: (source: SubscriptionSource) => void;
  dispatch: Dispatch;
};

interface ComponentOwnProps extends RouteComponentProps {
  game: IGame;
  prerollType: PrerollType;
  onEnd: () => void;
  innerRef: any;
  setIsPrerollReady: () => void;
}

type PrerollProps =
  StateProps
  & DispatchProps
  & ComponentOwnProps;

export enum PrerollType {
  PREROLL = 'pre-roll',
  REWARD = 'reward',
  INTERSTITIAL = 'interstitial',
}

const PrerollElementId = 'ark_pre-roll';

type CTAScreenSettingsType = {
  containerClass: string;
  colLeftClass: string;
  colRightClass: string;
  messageBoxClass: string;
  buttonClass: string;
  buttonArrowClass: string;
  buttonTextClass: string;
  messageText: string;
  buttonText: string;
  customTagline: string;
};

type adSettingsType = {
  width: number;
  height: number;
  element: string;
  autoPlay: boolean;
  adUrl: string | undefined;
  type: PrerollType;
  product: string;
  mute: boolean;
  onAdError: (msg: unknown) => void;
  finalCallback: () => void;
  CTAScreenConfig?: CTAScreenSettingsType;
  keyvalues: string,
};

class PrerollBase extends React.Component<PrerollProps> {
  private preRollStartTime: number;

  containerRef = React.createRef();

  timerId: any = -1;

  onAdError(msg) {
    globalErrorHandler({ error: msg, filename: 'Preroll.tsx', info: 'onAdError()' });
  }

  prerollStart() {
    this.preRollStartTime = Date.now();
    this.trackVideoStart();
  }

  getCTAScreensConfig() {
    return {
      containerClass: 'ctaContainer',
      colLeftClass: 'ctaColLeft',
      colRightClass: 'ctaColRight',
      messageBoxClass: 'ctaMessageBox',
      buttonClass: 'ctaButton',
      buttonArrowClass: 'ctaButtonArrow',
      buttonTextClass: 'ctaButtonText',
      messageText: ``,
      buttonText: TranslationService.translateIt('PLAY_BTN'),
      customTagline: getTagLine(this.props.plusFeatureFlag)
    };
  }

  // Called both on success and fail
  finalCallback() {
    this.props.onEnd();
    this.trackVideoEnd();
  }

  trackVideoStart() {
    const { prerollType, game, userFavoritesList } = this.props;

    game.isFavorite = userFavoritesList?.includes?.(game?.alias);

    switch (prerollType) {
      case PrerollType.REWARD:
        void Analytics.trackEvent(Analytics.games.rewardVideoStart(game));
        break;
      case PrerollType.INTERSTITIAL:
        void Analytics.trackEvent(Analytics.games.interstitialVideoStart(game));
        break;
      default:
        void Analytics.trackEvent(Analytics.games.prerollStart(game));
        break;
    }
  }

  trackVideoEnd() {
    const { prerollType, game } = this.props;

    switch (prerollType) {
      case PrerollType.REWARD:
        void Analytics.trackEvent(Analytics.games.rewardVideoEnd(game));
        break;
      case PrerollType.INTERSTITIAL:
        void Analytics.trackEvent(Analytics.games.interstitialVideoEnd(game));
        break;
      default:
        void Analytics.trackEvent(
          Analytics.games.prerollEnd(game, Math.floor((Date.now() - this.preRollStartTime) / 1000))
        );
        break;
    }
  }

  getAdSettings() {
    const { prerollType } = this.props;
    const isRewardOrInterstitial = prerollType === PrerollType.REWARD || prerollType === PrerollType.INTERSTITIAL;
    const adSettings: adSettingsType = {
      width: 640,
      height: 480,
      element: PrerollElementId,
      autoPlay: isRewardOrInterstitial,
      adUrl: undefined,
      type: prerollType || PrerollType.PREROLL,
      product: 'arena',
      mute: false,
      keyvalues: JSON.stringify([['env', environment.Name.toString()]]),
      onAdError: (msg: unknown) => {
        this.onAdError(msg);
      },
      finalCallback: () => {
        this.finalCallback();
      }
    };

    if (!prerollType || prerollType === PrerollType.PREROLL) {
      adSettings.CTAScreenConfig = this.getCTAScreensConfig();
    }

    return adSettings;
  }

  componentDidMount() {
    const { setIsPrerollReady, game, dispatch, setSubscriptionSource } = this.props;
    let queryUrl = UrlService.getQSParam(location.search, environment.ADS_QUERY_PARAM);

    queryUrl = queryUrl ? `?${environment.ADS_QUERY_PARAM}=${serialize(queryUrl).replaceAll('"', '')}` : '';
    const libraryPath = `${environment.VIDEO_ADS}${queryUrl}`;
    const findSkipAdsButton = () => document.querySelector(`.ctaRightButton`);
    const findRemoveAdsLink = () => document.querySelector(`.ctaRemoveAdsText`);
    const thePrerollElement: any = this?.containerRef?.current;
    const getActualProps = () => this.props;

    HttpUtils.loadScript(libraryPath)
      .then(() => {
        const adVideo: any = new (window as any).__ark_ads__.AdVideo();
        const adSettings = this.getAdSettings();

        adVideo.render(adSettings);
        this.prerollStart();
        setIsPrerollReady();

        const playButtonObserver = new MutationObserver(function () {
          const skipAdsButton = findSkipAdsButton();

          if (thePrerollElement && skipAdsButton) {
            skipAdsButton.addEventListener('click', (e: Event) => {
              e.stopPropagation();
              skipAdsButton.setAttribute('disabled', 'disabled');
              skipAdsButtonHandler(dispatch, game, getActualProps()).finally(() => {
                skipAdsButton.removeAttribute('disabled');
              });
            });
            playButtonObserver.disconnect();
          }

          // remove ads link block
          const removeAdsLink = findRemoveAdsLink();

          if (thePrerollElement && removeAdsLink) {
            const controller = new AbortController();

            removeAdsLink.addEventListener('click', (e) => {
              e.stopPropagation();
              setSubscriptionSource(SubscriptionSource.PREROLL);
              void Analytics.trackEvent(Analytics.subscription.removeAdsGameScreen(game));
              window.open(UrlService.createURL(this.props.plusFeatureFlag
                ? '/plus?arkpromo=remove_ads'
                : 'https://advantage.arkadium.com?arkpromo=remove_ads'
              ), '_blank');
              controller.abort(); // not supported by all browsers
            });
            playButtonObserver.disconnect();
          }
        });

        playButtonObserver.observe(thePrerollElement, { subtree: true, childList: true });

      })
      .catch((err) => {
        globalErrorHandler({ error: err, filename: 'Preroll.tsx', info: 'HttpUtils.loadScript()' });
        this.props.onEnd();
      })
      .finally(() => {
        setIsPrerollReady();
      });

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      setIsPrerollReady();
    }, 7000);
  }

  render() {
    return (
      <>
        <Container ref={this.containerRef} fullScreen={false}>
          <Player
            fullScreen={false}
            loading={this.props.isPrerollSkipPriceLoading || this.props.isGemsAmountLoading}
          />
        </Container>
      </>
    );
  }
}

const Container = React.forwardRef(({ fullScreen, ...props }: any, ref) => (
  <div
    id="preroll-container"
    ref={ref}
    className={classNames(styles.container, {
      [styles.fullScreen]: fullScreen
    })}
    {...props}
  />
));
const Player = ({ fullScreen, loading, ...props }: any) => (
  <div
    id="ark_pre-roll"
    className={classNames(styles.player, {
      [styles.fullScreen]: fullScreen,
      loading: loading
    })}
    {...props}
  />
);
const mapStateToProps = (state: AppState) => {
  return {
    isPrerollSkipPriceLoading: isPrerollSkipPriceLoadingSelector(state),
    isGemsAmountLoading: isGemsAmountLoadingSelector(state),
    gemsAmount: gemsAmountSelector(state),
    gemsShopLocation: gemsShopLocationSelector(state),
    prerollSkipPrice: prerollSkipPriceSelector(state),
    userFavoritesList: state.userFavoritesList,
    plusFeatureFlag: state.arkConfig.plusFeature.isEnabled
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setSubscriptionSource: (source: SubscriptionSource) => dispatch(setSubscriptionSource(source)),
    dispatch
  };
};

export const Preroll = withRouter(
  connect<StateProps, DispatchProps, ComponentOwnProps>(mapStateToProps, mapDispatchToProps)(PrerollBase)
);
