import React, { RefObject, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';

import styles from './GameNavigation.css';
import { MiscUtils } from '../../../utils/MiscUtils';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { CollectionsIcon } from '../../atoms/Icons/Styleguide/CollectionsIcon';
import { FavoriteIconAdd, FavoriteIconRemove } from '../../atoms/Icons/Styleguide/GameNavigationIcons/FavoriteIcon';
import { LeaderboardIcon } from '../../atoms/Icons/Styleguide/GameNavigationIcons/LeaderboardIcon';
import { PlayIcon } from '../../atoms/Icons/Styleguide/GameNavigationIcons/PlayIcon';
import { GameSupportIcon } from '../../atoms/Icons/Styleguide/GameSupportIcon';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { favoritesService } from '../../features/Favorites/FavoritesService';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { CommentsIcon } from '../../FigmaStyleguide/Icons/CommentsIcon';
import { FullScreen } from '../../FigmaStyleguide/Icons/FullScreen';
import { FullScreenArrow } from '../../FigmaStyleguide/Icons/FullScreenArrow';
import { GameState, QUERY_STRING_CONSTS } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { gamesSlugsFullScreen } from '../../models/GamesList';
import { Tooltip } from '../../molecules/Tooltip/Tooltip';
import { Analytics } from '../../services/Analytics/Analytics';
import { UrlService } from '../../services/UrlService';
import { setActiveGameView } from '../../store/ducks/games';
import { setSideMenuActivePage, setSideMenuOpened } from '../../store/ducks/layout';
import { LeaderboardNotificationIcon } from '../Leaderboard/LeaderboardNotificationIcon';

type GameNavigationProps = {
  game: IGame;
  background: string;
  pageDescriptionRef: RefObject<HTMLElement>;
  openFullScreen: () => void;
  exitFullScreen: () => void;
  adFree?: boolean;
};

function removeScroll() {
  document.body.style.overflow = 'hidden';
  document.body.style.overflowY = 'hidden';
}

function addScroll() {
  document.body.style.overflow = 'visible';
  document.body.style.overflowY = 'visible';
}

export const GameNavigation = ({
  game,
  pageDescriptionRef,
  background,
  openFullScreen,
  exitFullScreen,
  adFree = false
}: GameNavigationProps) => {
  const dispatch = useDispatch();
  const subscription = useSelector(({ preLoadData }) => preLoadData.subscription);
  const gameState = useSelector(({ gameState }) => gameState);
  const leaderboardNotificationType = useSelector(({ leaderboardNotificationType }) => leaderboardNotificationType);
  const userHasCollectionNotification = useSelector(({ userHasCollectionNotification }) => userHasCollectionNotification);
  const { activeTab, activeTab2 } = useSelector(({ gamePageViewState }) => gamePageViewState);
  const plusFeatureFlag = useSelector(({ arkConfig }) => arkConfig?.plusFeature?.isEnabled);
  const [selectedItem, setSelectedItem] = useState(activeTab);
  const [navDisabled, setNavDisabled] = useState(false);
  const [commentsButtonHover, setCommentsButtonHover] = useState(false);
  const [leaderboardHover, setLeaderboardHover] = useState(false);
  const [collectionsHover, setCollectionsHover] = useState(false);
  const [supportHover, setSupportHover] = useState(false);
  const [fullScreenHover, setFullScreenHover] = useState(false);
  const [fullScreenHoverTracked, setFullScreenHoverTracked] = useState(false);
  const [fullScreenQuery, setFullScreenQuery] = useState(false);
  const tablistRef = useRef<HTMLDivElement>(null);
  const gameTabRef = useRef<HTMLButtonElement>(null);
  const favoriteRef = useRef<HTMLButtonElement>(null);
  const leaderboardTabRef = useRef<HTMLButtonElement>(null);
  const collectionsTabRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const isGameTabSelected = selectedItem === 'game';
  const isLeaderBoardTabSelected = selectedItem === 'leaderboard';
  const isFullScreenButtonSelected = selectedItem === 'fullscreen';
  const isSupportTabSelected = selectedItem === 'support';
  const isCollectionsTabSelected = selectedItem === 'collections';
  const isFullscreenModeOn = activeTab2 === 'fullscreen';
  const isLeaderboardTooltipVisible = isFullscreenModeOn && leaderboardHover;
  const isCollectionsTooltipVisible = isFullscreenModeOn && collectionsHover;
  const isSupportTooltipVisible = isFullscreenModeOn && supportHover;
  const isFullscreenTooltipVisible = !subscription && fullScreenHover && !navDisabled;
  const hasCollection = game.meta.hasCollection;
  const isCommentsTooltipVisibleFullScreen = isFullscreenModeOn && commentsButtonHover;

  useEffect(() => {
    const disabledState =
      [GameState.GAME, GameState.GAME_END, GameState.ADBLOCK, GameState.PREROLL].indexOf(gameState) === -1;

    setNavDisabled(disabledState);
  }, [gameState]);

  useEffect(() => {
    setSelectedItem(activeTab);
    window.addEventListener('orientationchange', exitFullScreen);

    return () => {
      window.removeEventListener('orientationchange', exitFullScreen);
    };
  }, [activeTab]);

  useEffect(() => {
    tablistRef.current?.addEventListener('keydown', handleArrowClick);

    return () => {
      tablistRef.current?.removeEventListener('keydown', handleArrowClick);
    };
  }, [activeTab2]);

  useEffect(() => {
    if (!MiscUtils.isServer) {
      setFullScreenQuery(
        UrlService.getQSParam(window.location.search, QUERY_STRING_CONSTS.FULL_SCREEN_TEST) === 'test'
      );
    }
  }, []);

  useEffect(() => {
    if (!subscription && fullScreenHover && !fullScreenHoverTracked) {
      Analytics.trackEvent(Analytics.games.fullScreenToolTipImpression(game));
      setFullScreenHoverTracked(true);
    }
  }, [fullScreenHover]);

  const selectActiveGameView = (newActiveTab: string) => {
    setSelectedItem(newActiveTab);

    if (gameState !== GameState.ADBLOCK) {
      dispatch(setActiveGameView({ activeTab: newActiveTab }));
    }
  };
  const switchToGameTab = () => {
    if (!navDisabled && !isFullscreenModeOn) {
      selectActiveGameView('game');
      gameTabRef.current?.focus();
    }
  };
  const switchToSupportTab = () => {
    if (!navDisabled && !isFullscreenModeOn) {
      dispatch(setSideMenuActivePage(HeaderSideMenuTabs.HELP_TAB));
      dispatch(setSideMenuOpened(true));
    }
  };
  const switchToLeaderBoardTab = () => {
    if (!navDisabled && !isFullscreenModeOn) {
      if (!isLeaderBoardTabSelected) {
        Analytics.trackEvent(Analytics.games.gameNavigationLeaderboardIconClick(game));
      }

      selectActiveGameView('leaderboard');
      leaderboardTabRef.current?.focus();
    }
  };
  const switchToCollectionsTab = () => {
    Analytics.trackEvent(Analytics.games.gameNavigationCollectionButton(game));

    if (!navDisabled && !isFullscreenModeOn) {
      selectActiveGameView('collections');
      collectionsTabRef.current?.focus();
    }
  };
  const handleArrowClick = (event: KeyboardEvent) => {
    if (isFullscreenModeOn) {
      return;
    }

    /** We use preventDefault method for preventing scrolling the page with ArrowUp usage.
     * If we call it outside of conditions Tab click behaviour will be broken.
     * */
    const { key } = event;

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      event.preventDefault();
      switchToGameTab();
    } else if (key === 'ArrowRight' || key === 'ArrowDown') {
      event.preventDefault();
      switchToLeaderBoardTab();
    }
  };
  const fullScreenButtonOnClick = () => {
    if (isFullscreenModeOn) {
      removeScroll();
      dispatch(setActiveGameView({ activeTab: 'game', activeTab2: '' }));
      exitFullScreen();
    } else {
      Analytics.trackEvent(Analytics.games.fullScreenButtonClick(game));
      addScroll();
      dispatch(setActiveGameView({ activeTab: 'game', activeTab2: 'fullscreen' }));
      openFullScreen();
    }
  };
  const subscribeButtonOnClick = () => {
    Analytics.trackEvent(Analytics.games.fullScreenToolTipClick(game));
    window.open(plusFeatureFlag ? UrlService.createURL('plus') : 'https://advantage.arkadium.com', '_blank');
  };
  const getCaption = () => {
    if (isFullscreenModeOn) {
      return 'Minimize';
    } else if (!subscription && fullScreenHover && !navDisabled) {
      return 'Subscribe';
    } else {
      return 'Full screen';
    }
  };
  const GameTabButton = () => (
    <Button
      ref={gameTabRef}
      className={classNames(styles.button, { [styles.active]: isGameTabSelected })}
      onClick={switchToGameTab}
      role="tab"
      ariaSelected={isGameTabSelected}
      ariaControls="game-tabpanel"
      id="game-tab"
      useDefaultStyles={false}
      focusable={isGameTabSelected}
    >
      <>
        <PlayIcon />
        <div className={styles.buttonCaption}>
          <I18nText keyName="NAV_GAME" />
        </div>
      </>
    </Button>
  );
  // favorites handler
  const user = useSelector((state) => state.user);
  const userFavoritesList = useSelector((state) => state.userFavoritesList);
  const isGameFavorite = userFavoritesList?.includes?.(game?.slug);
  const toggleFavoriteHintTextKey = !user
    ? 'FAVORITES_GAME_TAB_ADD_HINT'
    : isGameFavorite
      ? 'FAVORITES_GAME_TAB_REMOVE_HINT'
      : '';
  const toggleFavoriteTextKey = isGameFavorite ? 'FAVORITES_GAME_TAB_REMOVE' : 'FAVORITES_GAME_TAB_ADD';
  const toggleFavoriteHandler = async () => {
    if (!user) {
      return batch(() => {
        dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
        dispatch(setSideMenuOpened(true));
      });
    }

    await favoritesService[isGameFavorite ? 'removeFavorite' : 'addFavorite'](game.slug);
    Analytics.trackEvent(Analytics.favorites.addFavoritesGameNav({ game, gameFavorited: !isGameFavorite }));
  };
  const FavoriteToggleButton = () => (
    <Button
      ref={favoriteRef}
      className={classNames(styles.button, { [styles.active]: isGameTabSelected }, styles.buttonFavorite)}
      onClick={toggleFavoriteHandler}
      role="button"
      ariaSelected={isGameTabSelected}
      ariaControls={isGameFavorite ? 'remove-from-favorites' : 'add-to-favorite'}
      id="favorite-toggle"
      useDefaultStyles={false}
      focusable={isGameTabSelected}
    >
      <>
        {toggleFavoriteHintTextKey && (
          <div className={styles.buttonFavoriteHint}>
            <I18nText keyName={toggleFavoriteHintTextKey} />
          </div>
        )}
        {isGameFavorite ? <FavoriteIconRemove /> : <FavoriteIconAdd />}
        <div className={styles.buttonCaption}>
          <I18nText keyName={toggleFavoriteTextKey} />
        </div>
      </>
    </Button>
  );
  const GamePlayButton = () =>
    isGameTabSelected && favoritesService.isOn ? FavoriteToggleButton() : GameTabButton();

  return (
    <div style={isFullscreenModeOn ? { backgroundColor: background } : {}}>
      <div
        className={classNames(styles.list, {
          [styles.disabled]: navDisabled,
          [styles.noMargin]: adFree || game.isAdsFree,
          [styles.fullHeight]: isFullscreenModeOn
        })}
        role="tablist"
        aria-orientation="vertical"
        ref={tablistRef}
      >
        <GamePlayButton />

        <Button
          ref={leaderboardTabRef}
          className={classNames(styles.button, { [styles.active]: isLeaderBoardTabSelected })}
          onClick={switchToLeaderBoardTab}
          role="tab"
          ariaSelected={isLeaderBoardTabSelected}
          ariaControls="leaderboard-tabpanel"
          id="leaderboard-tab"
          focusable={isLeaderBoardTabSelected}
          onMouseOver={() => setLeaderboardHover(true)}
          onMouseLeave={() => setLeaderboardHover(false)}
        >
          <div className={classNames({ [styles.disabled]: isFullscreenModeOn })}>
            <LeaderboardIcon />
            <div className={styles.buttonCaption}>
              <I18nText keyName="LEADERBOARD" />
            </div>
            {leaderboardNotificationType.ICON && <LeaderboardNotificationIcon place="nav" />}
          </div>
          <Tooltip
            show={isLeaderboardTooltipVisible}
            text={'Exit full screen to view leaderboard'}
            className={classNames(styles.tooltipGameNavigation, styles.tooltipLeaderboard)}
            showDelay={100}
          />
        </Button>

        {/* if hasCollection string value === true, then show tab on prod and devs, if value === dev, then only on devs */}
        {(BUILD_ENV === 'prod' && hasCollection) ||
          (BUILD_ENV !== 'prod' && hasCollection && (
            <Button
              ref={collectionsTabRef}
              className={classNames(styles.button, { [styles.active]: isCollectionsTabSelected })}
              onClick={switchToCollectionsTab}
              role="tab"
              ariaSelected={isCollectionsTabSelected}
              ariaControls="collections-tabpanel"
              id="collections-tab"
              useDefaultStyles={false}
              focusable={isCollectionsTabSelected}
              onMouseOver={() => setCollectionsHover(true)}
              onMouseLeave={() => setCollectionsHover(false)}
            >
              <div className={classNames({ [styles.disabled]: isFullscreenModeOn })}>
                <CollectionsIcon />
                {userHasCollectionNotification && <LeaderboardNotificationIcon place="nav" />}
                <div className={styles.buttonCaption}>
                  <I18nText keyName="Collections" />
                </div>
              </div>
              <Tooltip
                show={isCollectionsTooltipVisible}
                text={'Exit full screen to view collections'}
                className={styles.tooltip}
                showDelay={100}
              />
            </Button>
          ))}

        <Button
          ref={gameTabRef}
          className={classNames(styles.button, { [styles.active]: false })}
          onClick={switchToSupportTab}
          role="tab"
          ariaSelected={isSupportTabSelected}
          ariaControls="game-tabpanel"
          id="support-tab"
          useDefaultStyles={false}
          focusable={isSupportTabSelected}
          onMouseOver={() => setSupportHover(true)}
          onMouseLeave={() => setSupportHover(false)}
          onBlur={() => setSupportHover(false)}
        >
          <div className={classNames({ [styles.disabled]: isFullscreenModeOn })}>
            <GameSupportIcon />
            <div className={styles.buttonCaption}>
              <I18nText keyName="NAV_SUPPORT" />
            </div>
          </div>
          <Tooltip
            show={isSupportTooltipVisible}
            text={'Exit full screen to view support'}
            className={styles.tooltip}
            showDelay={100}
          />
        </Button>

        <Button
          className={styles.button}
          onClick={() => {
            if (!isFullscreenModeOn) {
              pageDescriptionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
              Analytics.trackEvent(Analytics.games.commentsButton(game));
            }
          }}
          onMouseOver={() => {
            setCommentsButtonHover(true);
          }}
          onMouseLeave={() => {
            setCommentsButtonHover(false);
          }}
        >
          <div className={classNames({ [styles.disabled]: isFullscreenModeOn })}>
            <CommentsIcon className={styles.commentsIcon} />
            <div className={classNames(styles.buttonCaption, styles.visibleOnDisabled)}>
              <I18nText keyName="COMMENTS" />
            </div>
            <Tooltip
              show={isCommentsTooltipVisibleFullScreen}
              text={'Exit full screen to view comments'}
              className={classNames(styles.tooltipGameNavigation, styles.tooltipCommentsFullScreen)}
              showDelay={100}
            />
          </div>
        </Button>

        {(gamesSlugsFullScreen.includes(game.slug) || fullScreenQuery) && (
          <Button
            className={classNames(styles.button, styles.lastButton, {
              [styles.active]: isFullScreenButtonSelected,
              [styles.turnedOff]: !subscription
            })}
            onClick={() => {
              if (!navDisabled) {
                !subscription && fullScreenHover ? subscribeButtonOnClick() : fullScreenButtonOnClick();
              }
            }}
            onMouseOver={() => setFullScreenHover(true)}
            onFocus={() => setFullScreenHover(true)}
            onMouseLeave={() => setFullScreenHover(false)}
            onBlur={() => setFullScreenHover(false)}
          >
            {(subscription || !fullScreenHover || navDisabled) && (
              <>
                <FullScreen fullScreen={isFullscreenModeOn} />
              </>
            )}
            {!subscription && fullScreenHover && !navDisabled && <FullScreenArrow />}
            <div className={styles.buttonCaption}>
              <I18nText keyName={getCaption()} />
            </div>
            <Tooltip
              show={isFullscreenTooltipVisible}
              text={plusFeatureFlag ? t('FULLSCREEN_MESSAGE_PLUS') : t('FULLSCREEN_MESSAGE_ADVANTAGE')}
              className={classNames(styles.tooltipGameNavigation, styles.tooltipFullScreen)}
              showDelay={100}
            />
          </Button>
        )}
      </div>
    </div>
  );
};

GameNavigation.displayName = 'GameNavigation';
